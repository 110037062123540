.project {
    width: 750px;
    height: 550px;
    border-radius: 50px;
}

a{
    color: inherit;
    text-decoration: none;
}

.pointer-cursor:hover {
    cursor: pointer;
}

.blurb-font {
    font-size: 18px;
}

.logo-text {
    font-size: 50px;
}

.project-content {
    width: 600px;
    display: block;
}

.nutri-app-logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.bunch-notes-logo-container {
    text-align: center;
    margin-bottom: 35px;
}

.aqua-vybes-logo-container {
    text-align: center;
    margin-bottom: 65px;
}

.idea-card-logo-container {
    text-align: center;
    margin-bottom: 40px;
}

.ghost-jazz-logo {
    justify-self: center;
    margin-bottom: 25px;
}

.inherit-size {
    width: inherit;
    height: inherit;
}