.gallery {
    margin-bottom: 15px;
    size: 75px;
}

.gallery-button-container {
    width: 75px;
    display: flex;
    justify-content: center;
    position: relative;
}

.gallery-button {
    position: absolute;
    align-self: center;
    padding: 5px 5px;
    background-color: transparent;
    border: none;
}

.left-button {
    float: left;
}

.flip {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    left: 70px;
}

.normal {
    right: 70px;
}

.right-button {
    float: right;
}

.arrow {
    width: 50px;
    height: 50px;
}

.arrow:hover {
    height: 65px;
    width: 65px;
}

.arrow:active {
    height: 50px;
}

.proj-button {
    background-color: rgb(129, 126, 126);
    width: 20px;
    height: 20px;
    margin: 8px;
    border-style: solid;
    border-width: 2px;
    border-radius: 100px;
    border-color: rgb(79, 77, 77);

    position: relative;
    display: inline-block;

}

.active {
    background-color: rgb(198, 196, 196);
    width: 25px;
    height: 25px;
}

.proj-button:hover {
    width: 30px;
    height: 30px;
    box-shadow: 5px 5px 5px #b0aeae;
}

.proj-button-container {
    margin-top: -15px;
    width: 75px;
    height: 75px;
}

.proj-button .tooltip {
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.proj-button:hover .tooltip {
    visibility: visible;
}