.project-item {
    background-color: var(--grey);
    width: 250px;
    height: 250px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    display: flex;
    justify-content: center;
}

.project-page {
    background-image: url(files/background\ image.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 1200px;
    margin-top: 0px;
    padding:40px;
}