:root {
  --green: #98ce00;
  --white: #FFFFFC;
  --grey: #757780;
  --black: #001011;
  --blue: #6CCFF6 ;

  --nutriappbg: #386641;
  --ghostjazzbg: #0dd091;
  --aquavybesbg: #ccfffc;
  --bunchnotesbg: #f7efb2;
  --kalarasbg: #ec4242;
  --terrainbg: #22401b;
  --ideabg: rgba(50,67,95,1);     
  /* --blue: rgba(174,201,230,1);
  --darkblue: rgba(50,67,95,1); */

  --nutriappft: #ffffff;
  --ghostjazzft: #3c3b3b;
  --aquavybesft: #0195de;
  --bunchnotesft: #000000;
  --kalarasft: #fff5e0;
  --terrainft: #e0cfcf;
  --ideaft: #ffffff;


}

#root {
  position: absolute;
  width: 100vw;
  left: 0;
}


body {
  margin: 0;
  font-family: Courier;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nutriapp {
  background-color: var(--nutriappbg);
  color: var(--nutriappft);
}

.ghostjazz {
  background-color: var(--ghostjazzbg);
  color: var(--ghostjazzft);
}

.aquavybes {
  background-color: var(--aquavybesbg);
  color: var(--aquavybesft);
}

.bunchnotes {
  background-color: var(--bunchnotesbg);
  color: var(--bunchnotesft);
}

.kalaras {
  background-color: var(--kalarasbg);
  color: var(--kalarasft);
}

.trainedterrain {
  background-color: var(--terrainbg);
  color: var(--terrainft);
}

.ideacard {
  background-color: var(--ideabg);
  color: var(--ideaft);
}

.menu-bar-container {
  position: sticky;
  top: 0;
  z-index: 2;
}

.menu-bar {
  width: 100%;
  height: 90px;
  background-color: var(--blue);
  display: flex;  
  align-items: center;
}

.menu-bar-divider {
  width: 100%;
  height: 20px;
  background-color: var(--green);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.menu-bar-item-row {
  position: absolute;
  right: -70px;
  top: 10px;
  width: 620px;  
}

.menu-bar-item {
  font-size: 28px;
  cursor: pointer;
  text-decoration: none;
  color: var(--black);
}

.menu-bar-item-container {
  display: inline-block;
  padding: 15px;
  position: relative; 
}

.menu-bar-item:hover {
  text-decoration: underline;
}

.active-bar-item{
  color: var(--grey);
}

.icon {
  padding-bottom: 2px;
}

.icon:hover {
  border-bottom: 3px solid black;
}
