.main-page {
    background-image: url(files/background\ image.jpg);
    background-size: 100% 100%;
    background-repeat: repeat-y;
}

.page-container {
    
}

.divider {
    background-color: var(--green);
    width: 100%;
    height: 20px;
}
  
.blurb-text {
    font-size: 20px;
    width: 80%;
}

.name-text {
    font-size: 70px;
    text-align: center;
    font-family: Tahoma;
}

.image-placeholder {
    width: 500px;
    height: 500px;
    background-color: grey;
    justify-self: center;
}
  
.blurb-container {
    width: inherit;
    margin: 10px 0px 30px;
}

.skills-section-container {
    margin: 30px 0px 30px;
}

.skills-text {
    text-align: center;
    font-size: 40px;
}

.skills-container {
    display: flex;
}

.skills-col {
    max-width: 250px;
    padding-left: 0;
    list-style-type: none;
    /* border: 3px solid #4e4f54 ;
    border-radius: 10px; */
}

.center-skills-col {
    
}

.skill-filler {
    max-width: 250px;
    min-width: 35px;
    display: flex;
    justify-content: center;
    padding-inline-start: 0px;
    border-radius: 5px;
}

.skill-filler-background-container {
    height: 100%;
}

.skill-filler-background {
    width: 3px;
    background-color: #4e4f54;
    height: 100%;
}

.skill-header {
    text-align: center;
    font-size: 22px;
    padding: 12px 2px;
}

.skill {
    padding-left: 4px;
    position: relative;
    display: inline-block;
    font-size: px;
}

.project-text-container {
    display: flex;
    justify-content: center;
}

.project-text {
    background-color: var(--green);
    text-align: center;
    width: 150px;
    border-radius: 5px;
    font-size: 17px;
    padding: 4px;
}

.skill-item {
    /* background-color: var(--blue); */
    margin-bottom: 10px;
    height: 25px;
    list-style-type: none;
    /* border-radius: 7px;
    border-color: rgb(14, 125, 135);
    border-style: solid;
    border-width: 2px; */
    width: 100%;
}

.skill-tooltip {
    visibility: hidden;
  width: 140px;
  background-color: var(--green);
  color: #4e4f54;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;

}


.left-tooltip {
    right: 105%;
}

.right-tooltip {
    left: 50%;
}

.skill:hover {
    background-color: var(--blue);
    border-radius: 5px;
}

.skill:hover .skill-tooltip{
    /* visibility: visible; */
  }

